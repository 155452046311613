import {
  differenceInCalendarDays,
  differenceInHours,
  format,
  formatDistanceToNowStrict,
  formatISO,
  parseISO,
  subDays,
  subHours,
  subMonths,
  subYears,
} from 'date-fns'

export default function toNow(dateString: string) {
  return formatDistanceToNowStrict(parseISO(dateString))
}

export function timestampToNow(seconds: number) {
  const date = new Date(seconds * 1000)
  return toNow(date.toISOString())
}

export function toNowShort(dateString: string) {
  // if the date string is empty, return an empty string
  if (dateString === '')
    return ''

  // split the distance string into an array of number + unit
  const distanceArray = formatDistanceToNowStrict(parseISO(dateString)).split(' ')

  // if this is months, return 'mo' instead of 'm' so we can tell minutes apart from months
  if (distanceArray[1] === 'months' || distanceArray[1] === 'month') {
    return `${distanceArray[0]}${distanceArray[1].substring(0, 2)}`
  }

  // return 'Now' if less than 1 minute
  if (distanceArray[1] === 'seconds' || distanceArray[1] === 'second') {
    return 'Now'
  }

  // if more than one year the unit will be plural instead of 'year'
  // in this case return the date in the format of 'd, MMM, yy' (e.g. 1 Jan 21)
  if (distanceArray[1] === 'years') {
    return format(parseISO(dateString), 'd MMM yy')
  }

  // otherwise, return the first number + the first letter of the unit
  return `${distanceArray[0]}${distanceArray[1].substring(0, 1)}`
}

export function toLongformDate(dateString: string) {
  return format(parseISO(dateString), 'MM/dd/yyyy hh:mm a')
}

export const getXAgo = (dateString: string) => {
  const distanceArray = formatDistanceToNowStrict(parseISO(dateString)).split(' ')
  const shortDate = toNowShort(dateString)

  if (shortDate === 'Now') {
    return toNowShort(dateString)
  }

  if (distanceArray[1] === 'years') {
    return shortDate
  }

  return `${toNowShort(dateString)} ${$t('ago')}`
}

/**
 * Util wrapper for date-fns differenceInCalendarDays - number of full day periods between two dates.
 * Fractional days are truncated towards zero.
 * Returns the number of days or undefined in the case of an invalid date.
 */
export function daysAgoFromNow(previousDate: string | Date): number | undefined {
  // https://date-fns.org/v2.30.0/docs/differenceInDays
  const now = new Date()
  const previous = new Date(previousDate)
  // If we're giving a date, return the difference
  if (previousDate instanceof Date) {
    // differenceInCalendarDays(the later date, the earlier date)
    return differenceInCalendarDays(now, previousDate)
  }
  // Otherwise, check that we have a valid date conversion from the string
  const days = previous.toString() === 'Invalid Date' ? undefined : differenceInCalendarDays(now, previous)
  return days
}

export function isMoreThanXDaysAgo(date: string | Date, days: number): boolean {
  const daysAgo = daysAgoFromNow(date)
  return daysAgo !== undefined && days >= 0 && daysAgo >= days
}

export function getChartBarInterval(start: string, end: string): string {
  if (!start || !end) {
    return '24h'
  }

  const durationInDays = differenceInCalendarDays(end, start)

  // If it's two days or less, show 15m intervals
  if (durationInDays <= 2) {
    return '15m'
  }

  // If it's one week or less, show 2h intervals
  if (durationInDays <= 7) {
    return '2h'
  }

  // If it's one month or less, show 8h intervals
  if (durationInDays <= 31) {
    return '8h'
  }

  // Default to one bar per day if the duration is more than 31 days
  return '24h'
}

/*
 * This is a wrapper for date-fns format
 * used for date only formats without a time zone offset.
 * See date-fns issue: https://github.com/date-fns/date-fns/issues/489#issuecomment-302271425
 * The solution: https://github.com/date-fns/date-fns/issues/489#issuecomment-357732898
 * If the ISO string does not include hours, use this function to format the date.
 */
export function formatUTCDate(isoStr: string, formatOptions: string) {
  const [year, month, day] = isoStr.substring(0, 10).split('-')
  return format(new Date(Number
    .parseInt(year, 10), Number.parseInt(month, 10) - 1, Number.parseInt(day, 10)), formatOptions)
}

/*
 * @param {string} filterValue - a filter value (past24Hours, past7Days, past30days)
 * Converts a filter value to an ISO string.
 * Will return an empty string if passed a filter value that is not supported.
 */
export function formatDateFilterToISOString(filterValue: string): string {
  switch (filterValue) {
    case 'past24Hours':
      return formatISO(subHours(new Date(), 24))
    case 'past7Days':
      return formatISO(subDays(new Date(), 7))
    case 'past30Days':
      return formatISO(subDays(new Date(), 30))
    default:
      return ''
  }
}

export function formatToDate(date: Date): string {
  return `${format(date, 'M-d-yyyy')}`
}

export function formatToHour(date: Date): string {
  return `${format(date, 'h:mm a')}`
}

export function formatToDateAndHour(date: Date): string {
  return `${format(date, 'M/dd ha')}`
}

export function formatToLocaleDateString(date: Date, showHour: boolean = true): string {
  const userLocale = navigator.language
  if (showHour) {
    return `${date
      .toLocaleDateString(userLocale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
      })
      .replace(' at', ', ')}`
  } else {
    return `${date
      .toLocaleDateString(userLocale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
      .replace(' at', ', ')}`
  }
}

export function isLessThanAnHourOld(date: string) {
  const now = new Date().toISOString()
  if (date) {
    // differenceInHours(laterDate, earlierDate)
    return differenceInHours(now, date) < 1
  }
  return false
}

export function convertDateStringToUTC(dateString: string): string {
  if (dateString === 'last24Hours') {
    return formatISO(subDays(new Date(), 1))
  } else if (dateString === 'last7Days') {
    return formatISO(subDays(new Date(), 7))
  } else if (dateString === 'lastMonth') {
    return formatISO(subMonths(new Date(), 1))
  } else if (dateString === 'lastYear') {
    return formatISO(subYears(new Date(), 1))
  }

  // Default to last 24 hours if nothing matches
  return formatISO(subDays(new Date(), 1))
}
