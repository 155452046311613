import { definePreset } from '@primevue/themes'
import Aura from '@primevue/themes/aura'

const Ghost = definePreset(Aura, {
  semantic: {
    primary: {
      0: '{indigo.50}',
      50: '{indigo.50}',
      100: '{indigo.100}',
      200: '{indigo.200}',
      300: '{indigo.300}',
      400: '{indigo.400}',
      500: '{indigo.500}',
      600: '{indigo.600}',
      700: '{indigo.700}',
      800: '{indigo.800}',
      900: '{indigo.900}',
      950: '{indigo.950}',
    },
    colorScheme: {
      light: {
        surface: {
          0: '#ffffff',
          50: '{zinc.50}',
          100: '{zinc.100}',
          200: '{zinc.200}',
          300: '{zinc.300}',
          400: '{zinc.400}',
          500: '{zinc.500}',
          600: '{zinc.600}',
          700: '{zinc.700}',
          800: '{zinc.800}',
          900: '{zinc.900}',
          950: '{zinc.950}',
        },
      },
    },
  },

  components: {
    button: {
      colorScheme: {
        dark: {
          outlined: {
            primary: {
              border: {
                color: '{surface.700}',
              },
              color: '{surface.300}',
            },
          },
        },
        light: {
          outlined: {
            primary: {
              border: {
                color: '{surface.300}',
              },
              color: '{surface.600}',
            },
          },
        },
      },
    },
    datatable: {
      colorScheme: {
        dark: {
          header: {
            cell: {
              hover: {
                background: 'color-mix(in srgb, #404040,transparent 84%)',
              },
              selected: {
                color: '{white}',
                background: 'transparent',
              },
            },
          },
          row: {
            hover: {
              background: 'color-mix(in srgb, #404040,transparent 84%)',
            },
          },
        },
        header: {
          cell: {
            selected: {
              color: '{zinc.700}',
              background: 'transparent',
            },
          },
        },
      },
      css: () => `
        .p-datatable { 
          font-size: 0.75rem;
        } 
        .p-datatable-sort-icon { 
          height: 12px;
        }        
        .p-datatable-sort-icon[sorted="false"] { 
          display: none;
        }
        .p-datatable-paginator-bottom {
          border: none;
        }        
      `,
    },
    message: {
      content: {
        padding: '0.25rem 0.5rem',
      },
      close: {
        button: {
          width: '1rem',
          height: '1rem',
        },
      },
    },
    paginator: {
      colorScheme: {
        dark: {
          nav: {
            button: {
              selected: {
                color: '{zinc.200}',
                background: '{zinc.800}',
              },
            },
          },
        },
        light: {
          nav: {
            button: {
              selected: {
                color: '{zinc.600}',
                background: '{zinc.100}',
              },
            },
          },
        },
      },
      nav: {
        button: {
          height: '24px',
          width: '24px',
          border: {
            radius: '6px',
          },
        },
      },
      css: () => `
      .p-paginator {
        justify-content: right;
      }
      .p-paginator-current {
        margin-top: 2.5px;
      } 
    `,
    },
    tag: {
      colorScheme: {
        light: {
          primary: {
            background: '{zinc.100}',
            color: '{zinc.600}',
          },
        },
        dark: {
          primary: {
            background: '{zinc.950}',
            color: '{zinc.300}',
          },
        },
      },
      font: {
        weight: '400',
        size: '12px',
      },
      border: {
        radius: '2px',
      },
      padding: '0.1rem 0.5rem 0 0.5rem',
    },
    chip: {
      extend: {
        // Extension to be used by the custom css below: dt('chip.label.font.weight')
        font: {
          weight: '500',
        },
      },
      border: {
        radius: '6px',
      },
      colorScheme: {
        light: {
          primary: {
            color: '{surface.600}',
          },
        },
        dark: {
          primary: {
            color: '{surface.300}',
          },
        },
      },
      // PrimeVue doesn't support font theming so we need to use custom css for it
      css: ({ dt }: { dt: (key: string) => string }) => `
        .p-chip { 
          color: ${dt('chip.colorScheme.primary.color')};
          font-weight: ${dt('chip.font.weight')};
        }        
      `,
    },
    inputtext: {
      padding: {
        x: '0.5rem',
        y: '0.25rem',
      },
      css: () => `
        .p-inputtext {
          font-size: 0.875rem !important;
        }
      `,
    },
    floatlabel: {
      active: {
        font: {
          size: '0.875rem',
        },
      },
      css: () => `
        .p-floatlabel label {
          font-size: 0.875rem;
        }
      `,
    },
    menu: {
      item: {
        padding: '.25rem .5rem',
      },
      list: {
        padding: '0.25rem',
      },
    },
    select: {
      css: () => `
        .p-select-option-label {
          font-size: 0.875rem;
        }
          .p-select-label {
          font-size: 0.875rem;
        }
      `,
    },
    multiselect: {
      css: () => `
      .p-multiselect-option {
        font-size: 0.875rem;
      }
      `,
    },
    tabs: {
      tab: {
        padding: '0.5rem 1rem',
      },
    },
    toast: {
      css: () => `
        .p-toast-close-button {
          margin: 0;
        }

        .p-toast-summary:empty {
          display: none;
        }

        .p-toast-message-content {
          align-items: center;
        }
      `,
    },
  },
})

export default {
  preset: Ghost,
  options: {
    darkModeSelector: '.ghost-dark',
    lightModeSelector: '.ghost-light',
    ripple: false,
  },
}
