import { storeToRefs } from 'pinia'
import useUsersStore from '@/stores/users'
import { Theme } from '@/types'

export default function useInitialTheme() {
  const usersStore = useUsersStore()
  const { theme } = storeToRefs(usersStore)

  // Check on the initial mount of the app to see if the user has a theme set
  //  The default value in the store is undefined
  if (!theme.value) {
    // Check if the user's system is set to dark mode
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      theme.value = Theme.Dark
    }

    // Check if the user's system is set to light mode
    if (window.matchMedia('(prefers-color-scheme: light)').matches) {
      theme.value = Theme.Light
    }
  }

  if (theme.value && [Theme.Dark, Theme.Light].includes(theme.value)) {
    document.querySelector('html')?.classList.add(theme.value)

    // Apply Unovis dark mode theme if dark mode is set
    if (theme.value === Theme.Dark) {
      document.querySelector('body')?.classList.add('theme-dark')
    }
  } else {
    // If somehow we don't have a theme set in the store or
    // it has not been set from the system preferences, set the theme to 'ghost-dark'
    theme.value = Theme.Dark
    document.querySelector('html')?.classList.add(Theme.Dark)
    // Apply Unovis dark mode theme
    document.querySelector('body')?.classList.add('theme-dark')
  }
}
