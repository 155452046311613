import { createAuth0 } from '@auth0/auth0-vue'
import useUsersStore from '@/stores/users'
import { storeSessionAndGenerateKey } from '@/utils/auth'

export default defineNuxtPlugin(async ({ vueApp }) => {
  const config = useRuntimeConfig()
  const route = useRoute()

  const auth0 = createAuth0({
    domain: config.public.auth0Domain as string,
    clientId: config.public.auth0ClientId as string,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: config.public.auth0Audience as string,
    },
  })

  // eslint-disable-next-line ts/no-explicit-any
  vueApp.use(auth0 as any)

  // Verify the user's current session when the tab becomes visible after it'd been hidden
  // Redirect to the login page if the user doesn't have an active session
  document.addEventListener('visibilitychange', async () => {
    // Exclude the login page
    if (document.visibilityState === 'visible' && route.fullPath !== '/login') {
      try {
        await auth0.getAccessTokenSilently({
          cacheMode: 'off',
        })

        // Refresh the user state when coming back from a stale tab
        const usersStore = useUsersStore()
        await usersStore.fetchCurrentUserStatus()
      } catch (error) {
        console.error(error)

        const stateUUID = storeSessionAndGenerateKey({
          redirectURI: route.fullPath,
        })

        const auth0RedirectURI = `${window.location.origin}?k=${stateUUID}`

        auth0.loginWithRedirect({
          authorizationParams: {
            redirect_uri: auth0RedirectURI,
          },
        })
      }
    }
  })
})
