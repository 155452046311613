import { storeToRefs } from 'pinia'
import { useAuth0 } from '@auth0/auth0-vue'
import useUsersStore from '@/stores/users'

export default defineNuxtPlugin(() => {
  const usersStore = useUsersStore()
  const router = useRouter()
  const { getAccessTokenSilently } = useAuth0()
  const { currentUser } = storeToRefs(usersStore)

  /* This creates a global resolve guard,
   for more info: https://router.vuejs.org/guide/advanced/navigation-guards.html#Global-Resolve-Guards
   This will trigger on each navigation, but is called before the navigation is confirmed */
  router.beforeResolve(async (to) => {
    if (currentUser.value) {
      const newOrgFromList = currentUser.value.organizations?.find(org => org.id === to.query.org_id)

      /* If we don't have an org_id in the route query,
      add the current user's organization_id to the route */
      if (!to.query.org_id && currentUser.value.organization_id) {
        return navigateTo({
          path: to.path,
          query: { ...to.query, org_id: currentUser.value.organization_id },
        }, { replace: true })
      } else if (to.query.org_id !== currentUser.value.organization_id && newOrgFromList) {
        /* If the org query param does not match the current user's org
            log the user into the new org, fetch the current user to refresh the org data,
            add the new org id to the URL and navigate */
        try {
          // log the user into the new org
          await getAccessTokenSilently({
            authorizationParams: {
              organization: newOrgFromList.auth0_id,
              ignoreCache: true,
            },
            cacheMode: 'off',
          })
          await usersStore.fetchCurrentUserStatus()

          return navigateTo({
            path: to.path,
            query: { ...to.query, org_id: newOrgFromList.id },
          }, { replace: true })
        } catch (error) {
          useErrorToast($t('changeOrganizationError'))
          console.error(error)
        }
      } else if (to.query.org_id && !newOrgFromList) {
        /* If we have an org_id in the query params but the user does not belong
        to the org from the query params, clear the query params,
        and send the user to the page with an error toast */

        // Allow the navigation to occur before triggering the error toast
        setTimeout(() => useErrorToast($t('resourceNotFound')), 500)
        return navigateTo({
          path: to.path,
          query: { org_id: currentUser.value.organization_id },
        }, { replace: true })
      }
    }
  })
})
