import { AppsService, DomainsService, HostsService, IssueCategoriesService, IssuesService } from '@ghostsecurity/api-client'
import type { ApiError } from '@ghostsecurity/api-client'
import { defineStore } from 'pinia'
import type { LocationQueryValueRaw } from 'vue-router'
import type { FilterOption } from '@/types'
import { convertDateStringToUTC } from '@/utils/date'
import i18n from '@/utils/i18n'

const useFiltersStore = defineStore('filters', {
  state: () => ({
    availableFilters: [] as FilterOption[],
    activeFilters: [] as FilterOption[],
    filterDrawerActiveFilters: [] as FilterOption[],
    appsById: {} as { [key: string]: { key: string, name: string } },
    domainsById: {} as { [key: string]: { key: string, name: string } },
    hostsById: {} as { [key: string]: { key: string, name: string } },
    issueCategoriesById: {} as { [key: string]: { key: string, name: string } },
    issuesById: {} as { [key: string]: { key: string, name: string } },
  }),

  getters: {
    // This getter is used to generate the query params for the active filters
    queryParamsFromActiveFilters(state) {
      return state.activeFilters.reduce((accumulator: { [key: string]: string }, filter: FilterOption) => {
        if (Array.isArray(filter.value)) {
          accumulator[filter.key] = filter.value.map(value => value.value as string).join(',')
        } else {
          if (filter.convertAsDate) {
            accumulator[filter.key] = convertDateStringToUTC(filter.value?.value as string)
          } else {
            accumulator[filter.key] = filter.value?.value as string
          }
        }

        return accumulator
      }, {})
    },
    selectedFilterCount(state) {
      let count = 0

      state.activeFilters.forEach((filter) => {
        if (Array.isArray(filter.value)) {
          count += filter.value.length
        } else {
          count += filter.value ? 1 : 0
        }
      })

      return count
    },
  },

  actions: {
    async fetchAppOptionById({ id }: { id: string }) {
      if (this.appsById[id]) {
        return this.appsById[id]
      }

      try {
        const app = await AppsService.getApp({ id })

        // Cache the app in the store
        this.appsById[id] = { key: app.id, name: app.name }

        return this.appsById[id]
      } catch (e) {
        useApiErrorToast(e as ApiError, { defaultMessage: i18n.global.t('fetchFiltersError') })
        return undefined
      }
    },

    async fetchAppFilterOptions({ query, page } = { query: '', page: 1 }) {
      try {
        const appsList = await AppsService.listApps({
          name: query,
          page,
          size: 10,
          orderBy: 'name',
        })

        // If no results simply clear the app options and return
        if ((!appsList.items || appsList.items.length === 0) && page === 1) {
          return { hasMorePages: false, totalResults: 0 }
        }

        const appIdFilterOption = this.availableFilters.find(filter => filter.key === 'appId')

        if (!appIdFilterOption) {
          throw new Error('appId filter option not found')
        }

        // Newly loaded options formatted correctly
        const newOptions = appsList.items.map(app => ({ key: app.id, name: app.name }))

        // If this is not the first page we need to append the new results to the existing ones
        if (page > 1) {
          appIdFilterOption.options = [...(appIdFilterOption.options || []), ...newOptions]
        } else {
          appIdFilterOption.options = [...newOptions]
        }

        return { hasMorePages: appsList.pages > page, totalResults: appsList.total }
      } catch (e) {
        useApiErrorToast(e as ApiError, { defaultMessage: i18n.global.t('fetchFiltersError') })
        return { hasMorePages: false, totalResults: 0 }
      }
    },

    async fetchDomainById({ id }: { id: string }) {
      try {
        const domain = await DomainsService.getDomain({ id })

        // Cache the domain in the store
        this.domainsById[id] = { key: domain.id, name: domain.name }

        return this.domainsById[id]
      } catch (e) {
        useApiErrorToast(e as ApiError, { defaultMessage: i18n.global.t('fetchFiltersError') })
        return undefined
      }
    },

    async fetchDomainFilterOptions({ query, page } = { query: '', page: 1 }) {
      try {
        const domainsList = await DomainsService.listDomains({
          name: query,
          page,
          size: 10,
          orderBy: 'name',
        })

        // If no results simply clear the app options and return
        if ((!domainsList.items || domainsList.items.length === 0) && page === 1) {
          return { hasMorePages: false, totalResults: 0 }
        }

        const domainIdFilterOption = this.availableFilters.find(filter => filter.key === 'domainId')

        if (!domainIdFilterOption) {
          throw new Error('domainId filter option not found')
        }

        // Newly loaded options formatted correctly
        const newOptions = domainsList.items.map(domain => ({ key: domain.id, name: domain.name }))

        // If this is not the first page we need to append the new results to the existing ones
        if (page > 1) {
          domainIdFilterOption.options = [...(domainIdFilterOption.options || []), ...newOptions]
        } else {
          domainIdFilterOption.options = [...newOptions]
        }

        return { hasMorePages: domainsList.pages > page, totalResults: domainsList.total }
      } catch (e) {
        useApiErrorToast(e as ApiError, { defaultMessage: i18n.global.t('fetchFiltersError') })
        return { hasMorePages: false, totalResults: 0 }
      }
    },

    async fetchHostById({ id }: { id: string }) {
      if (this.hostsById[id]) {
        return this.hostsById[id]
      }

      try {
        const host = await HostsService.getHost({ id })

        // Cache the domain in the store
        this.hostsById[id] = { key: host.id, name: host.name }

        return this.hostsById[id]
      } catch (e) {
        useApiErrorToast(e as ApiError, { defaultMessage: i18n.global.t('fetchFiltersError') })
        return undefined
      }
    },

    async fetchHostFilterOptions({ query, page } = { query: '', page: 1 }) {
      try {
        const hostsList = await HostsService.listHosts({
          ...(query ? { name: query } : {}),
          page,
          size: 10,
          orderBy: 'name',
        })

        // If no results simply clear the app options and return
        if ((!hostsList.items || hostsList.items.length === 0) && page === 1) {
          return { hasMorePages: false, totalResults: 0 }
        }

        const hostIdFilterOption = this.availableFilters.find(filter => filter.key === 'hostId')

        if (!hostIdFilterOption) {
          throw new Error('hostId filter option not found')
        }

        // Newly loaded options formatted correctly
        const newOptions = hostsList.items.map(host => ({ key: host.id, name: host.name }))

        // If this is not the first page we need to append the new results to the existing ones
        if (page > 1) {
          hostIdFilterOption.options = [...(hostIdFilterOption.options || []), ...newOptions]
        } else {
          hostIdFilterOption.options = [...newOptions]
        }

        return { hasMorePages: hostsList.pages > page, totalResults: hostsList.total }
      } catch (e) {
        useApiErrorToast(e as ApiError, { defaultMessage: i18n.global.t('fetchFiltersError') })
        return { hasMorePages: false, totalResults: 0 }
      }
    },

    async fetchIssueCategoryOptions({ page } = { page: 1 }) {
      try {
        const categoriesList = await IssueCategoriesService.listIssueCategories({
          page,
          size: 10,
          orderBy: 'name',
        })

        // If no results simply clear the app options and return
        if ((!categoriesList.items || categoriesList.items.length === 0) && page === 1) {
          return { hasMorePages: false, totalResults: 0 }
        }

        const issueCategoryFilterOption = this.availableFilters.find(filter => filter.key === 'categoryId')

        if (!issueCategoryFilterOption) {
          throw new Error('issueCategories filter option not found')
        }

        // Newly loaded options formatted correctly
        const newOptions = categoriesList.items.map(category => ({ key: category.id, name: category.name }))

        // If this is not the first page we need to append the new results to the existing ones
        if (page > 1) {
          issueCategoryFilterOption.options = [...(issueCategoryFilterOption.options || []), ...newOptions]
        } else {
          issueCategoryFilterOption.options = [...newOptions]
        }

        return { hasMorePages: categoriesList.pages > page, totalResults: categoriesList.total }
      } catch (e) {
        useApiErrorToast(e as ApiError, { defaultMessage: i18n.global.t('fetchFiltersError') })
        return { hasMorePages: false, totalResults: 0 }
      }
    },

    async fetchIssueOptions({ page } = { page: 1 }) {
      try {
        const issuesList = await IssuesService.listIssues({
          page,
          size: 10,
        })

        // If no results simply clear the app options and return
        if ((!issuesList.items || issuesList.items.length === 0) && page === 1) {
          return { hasMorePages: false, totalResults: 0 }
        }

        const issueFilterOption = this.availableFilters.find(filter => filter.key === 'id')

        if (!issueFilterOption) {
          throw new Error('issues filter option not found')
        }

        // Newly loaded options formatted correctly
        const newOptions = issuesList.items.map(issue => ({ key: issue.id, name: issue.name }))

        // If this is not the first page we need to append the new results to the existing ones
        if (page > 1) {
          issueFilterOption.options = [...(issueFilterOption.options || []), ...newOptions]
        } else {
          issueFilterOption.options = [...newOptions]
        }

        return { hasMorePages: issuesList.pages > page, totalResults: issuesList.total }
      } catch (e) {
        useApiErrorToast(e as ApiError, { defaultMessage: i18n.global.t('fetchFiltersError') })
        return { hasMorePages: false, totalResults: 0 }
      }
    },

    async initializeFilters(initialFilters: FilterOption[]) {
      const router = useRouter()

      // Set the available filters in the store state
      this.setAvailableFilters(initialFilters)

      const currentQueryParams = { ...router.currentRoute.value.query }
      const queryParamsWithDefaults = {} as { [key: string]: string }

      initialFilters.forEach((filter) => {
        if (!currentQueryParams[filter.queryParamKey] && filter.defaultSelectedValues) {
          queryParamsWithDefaults[filter.queryParamKey] = filter.defaultSelectedValues.join(',')
        }
      })

      // Merge the current query params with the defaults
      await router.replace({ query: { ...currentQueryParams, ...queryParamsWithDefaults } })

      const updatedQueryParams = { ...router.currentRoute.value.query }

      const availableFilterKeys = initialFilters.map(filter => filter.queryParamKey)
      const queryParamKeys = Object.keys(updatedQueryParams)
      const selectedFilters = [] as FilterOption[]

      await Promise.all(queryParamKeys.map(async (queryParamKey) => {
        if (updatedQueryParams[queryParamKey] === null) {
          return
        }

        // Skip any query params that aren't filters
        if (!availableFilterKeys.includes(queryParamKey)) {
          return
        }

        // Find the filter that matches the query param key
        const filter = initialFilters.find(filter => filter.queryParamKey === queryParamKey)

        // If we can't find the filter, it isn't valid and should never get to this point
        if (!filter) {
          return
        }

        const currentQueryParamValue = Array.isArray(updatedQueryParams[queryParamKey]) ? (updatedQueryParams[queryParamKey] as string[])?.join(',') : updatedQueryParams[queryParamKey] as string
        const preparedQueryParamValue = currentQueryParamValue

        if (filter.type === 'autocomplete') {
          if (!filter.fetchHandlers?.byId) {
            return
          }

          const valueOption = await filter.fetchHandlers?.byId({ id: preparedQueryParamValue })

          // Don't set the option as selected if we can't find it
          // or fail to fetch it
          if (!valueOption) {
            return
          }

          // Set the fetched option as the selected value
          selectedFilters.push({
            ...filter,
            value: {
              displayName: valueOption.name as string,
              value: preparedQueryParamValue,
            },
          })
        } else if (filter.type === 'multiselect-autocomplete') {
          const ids = preparedQueryParamValue.split(',')

          const options = [] as Array<{ displayName: string, value: string }>

          // Make sure all promises are resolved from the requests
          await Promise.all(ids.map(async (id) => {
            const host = await filter.fetchHandlers?.byId?.({ id })
            options.push({ displayName: host?.name || '', value: id })
          }))

          // Set the fetched option as the selected value
          selectedFilters.push({
            ...filter,
            value: options,
          })
        } else if (filter.type === 'select') {
          const selectedOption = filter.options?.find(option => option.key === preparedQueryParamValue)

          // Don't set the option as selected if we can't find it
          if (!selectedOption) {
            return
          }

          selectedFilters.push({
            ...filter,
            value: {
              displayName: selectedOption.name,
              value: selectedOption.key,
            },
          })
        } else if (filter.type === 'multiselect' || filter.type === 'dynamic-multiselect') {
          // Fetch options first for dynamic multiselect
          // TODO: pass in the query in the future
          if (filter.type === 'dynamic-multiselect' && filter.fetchHandlers?.list) {
            await filter.fetchHandlers?.list({ query: '', page: 1 })
          }

          const selectedOptionValueKeys = preparedQueryParamValue.split(',')
          const selectedOptions = filter.options?.filter(option => selectedOptionValueKeys.includes(option.key))
          const selectedOptionsValues = selectedOptions?.map(option => ({
            displayName: option.name,
            value: option.key,
          }))

          selectedFilters.push({
            ...filter,
            value: selectedOptionsValues,
          })
        } else if (filter.type === 'multiselect-number-input') {
          const selectedOptionValueKeys = preparedQueryParamValue.split(',')
          const values = selectedOptionValueKeys.map(option => ({ displayName: option, value: option }))

          selectedFilters.push({
            ...filter,
            value: values,
          })
        } else {
          selectedFilters.push({
            ...filter,
            value: {
              displayName: updatedQueryParams[queryParamKey] as string,
              value: updatedQueryParams[queryParamKey] as string,
            },
          })
        }
      }))

      this.activeFilters = selectedFilters
    },

    setAvailableFilters(filters: FilterOption[]) {
      this.availableFilters = filters
    },

    setActiveFilters(updatedFilters: FilterOption[]) {
      const router = useRouter()

      this.activeFilters = updatedFilters

      const route = router.currentRoute.value

      const filterQueryParamKeys = this.availableFilters.map(filter => filter.queryParamKey)

      // Get the current query params
      const currentQueryParams = { ...route.query }
      const updatedQueryParams = {} as { [key: string]: LocationQueryValueRaw }

      // Apply and query params that aren't filters first
      Object.keys(currentQueryParams).forEach((queryParamKey) => {
        if (!filterQueryParamKeys.includes(queryParamKey)) {
          updatedQueryParams[queryParamKey] = currentQueryParams[queryParamKey]?.toString() || undefined
        }
      })

      // Then apply the selected filters
      updatedFilters.forEach((filter) => {
        let value: string | undefined

        if (Array.isArray(filter.value)) {
          value = filter.value.map(filterValue => filterValue.value).join(',')
        } else {
          value = filter.value?.value as string
        }

        updatedQueryParams[filter.queryParamKey] = value
      })

      router.replace({ query: updatedQueryParams })
    },

    resetState() {
      this.availableFilters = []
      this.activeFilters = []
    },
  },
})

export default useFiltersStore
