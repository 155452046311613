import { UsersService } from '@ghostsecurity/api-client'
import type { ApiError, User, UserCreate, UserUpdate } from '@ghostsecurity/api-client'
import { defineStore } from 'pinia'
import i18n from '@/utils/i18n'
import type { ErrorMessage, Theme, UsersOrderBy, UsersOrderByFields } from '@/types'
import useFiltersStore from '@/stores/filters'

/* Simple local User[] filter */
export const filterUsers = (users: User[], query: string) => {
  if (!query.length) {
    return users
  }

  const normalizedQuery = query.toLowerCase()

  return users.filter(
    ({ name, email }) => name.toLowerCase().includes(normalizedQuery) || email.toLowerCase().includes(normalizedQuery),
  )
}

export interface ViewSettings {
  email?: string
  name?: string
  orderBy?: UsersOrderBy
  enabled?: boolean
  page?: number
  size?: number
  teamId?: string
}

const useUsersStore = defineStore('users', {
  state: () => ({
    currentUser: undefined as User | undefined,
    enabledFeatureFlags: {} as Record<string, boolean>,
    theme: undefined as Theme | undefined,
    items: [] as User[],
    total: -1,
    pending: true,
    pendingIds: new Set(), // Set with ids of updating users
    dismissedPageDescriptions: {
      apps: false,
      apis: false,
      endpoints: false,
      hosts: false,
      codeRepos: false,
      aws: false,
      azure: false,
      categories: false,
      sastFindings: false,
      issuesVulnerabilities: false,
      issuesCampaigns: false,
      issuesDefinitions: false,
      domains: false,
      logForwarders: false,
    },
    viewSettings: {
      email: undefined as string | undefined,
      enabled: true,
      name: undefined as string | undefined,
      orderBy: 'name' as UsersOrderBy | undefined,
      page: 1,
      size: 25,
      teamId: undefined as string | undefined,
    },
  }),

  persist: {
    pick: ['enabledFeatureFlags', 'theme', 'viewSettings.size', 'dismissedPageDescriptions'],
    storage: localStorage,
  },

  getters: {
    currentUserIsLoggedIn(state) {
      return state.currentUser !== undefined
    },
    currentUserIsGhostAdmin(state) {
      return state.currentUser?.email?.endsWith('@ghost.security') || false
    },
    isFeatureEnabled: state => (featureFlagKey: string) => state.enabledFeatureFlags[featureFlagKey],
    noItems: state => state.total === 0 && !(state.viewSettings.name || state.pending),
    pageCount: state => Math.ceil(state.total / (state.viewSettings.size as number)),
    nothingFound: state => state.total === 0 && state.viewSettings.name && !state.pending,
    placeholderRowCount: (state) => {
      if (state.total !== -1) {
        return Math.min(state.viewSettings.size as number, state.total)
      }
      return state.viewSettings.size
    },
  },

  actions: {
    async fetchCurrentUserStatus() {
      try {
        const response = await UsersService.getSelf()

        this.currentUser = response

        return this.currentUser
      } catch (e) {
        return useApiErrorToast(e as ApiError, { sendNotifications: false })
      }
    },

    findById(resourceId: string) {
      return this.items.find(({ id }) => id === resourceId)
    },

    async rawFetch(settings: ViewSettings) {
      return UsersService.listUsers(settings)
    },

    async fetch(sendNotifications = true): Promise<true | ErrorMessage> {
      this.pending = true
      try {
        const filtersStore = useFiltersStore()
        const response = await this.rawFetch({
          ...this.viewSettings,
          ...filtersStore.queryParamsFromActiveFilters,
        })

        this.items = response.items
        this.total = response.total // this is a total of the filtered items

        return true
      } catch (e) {
        return useApiErrorToast(e as ApiError, {
          defaultMessage: i18n.global.t('fetchUsersError'),
          sendNotifications,
        })
      } finally {
        this.pending = false
      }
    },

    async create(users: UserCreate[], sendNotifications = true): Promise<User[] | ErrorMessage> {
      this.pending = true
      try {
        const response = await UsersService.createUsers({ requestBody: users })

        return response // return response here so it can be used without refetching data
      } catch (e) {
        return useApiErrorToast(e as ApiError, { sendNotifications })
      } finally {
        this.pending = false
      }
    },

    async deleteUser(user: User, sendNotifications = true): Promise<true | ErrorMessage> {
      try {
        await UsersService.deleteUser({ id: user.id })

        useSuccessToast(i18n.global.t('deleteUserSuccess', { userEmail: user.email }), sendNotifications)

        return true
      } catch (e) {
        const errorMessage
          = (e as ApiError).status === 403
            ? i18n.global.t('deleteUserPermissionError', { userEmail: user.email })
            : i18n.global.t('deleteUserError', { userEmail: user.email })

        return {
          error: errorMessage,
        }
      }
    },

    deleteFeatureFlag(key: string) {
      delete this.enabledFeatureFlags[key]
      return this.enabledFeatureFlags
    },

    setFeatureFlag(key: string, value: boolean) {
      this.enabledFeatureFlags[key] = value
      return this.enabledFeatureFlags
    },

    async updateItem(
      resourceId: string,
      requestBody: UserUpdate,
      sendNotifications = true,
    ): Promise<true | ErrorMessage> {
      this.pendingIds.add(resourceId)
      try {
        const updatedUser = await UsersService.updateUser({ id: resourceId, requestBody })

        if (requestBody.enabled === false) {
          useSuccessToast(i18n.global.t('userDeleted', { user: updatedUser?.name }), sendNotifications)
        } else {
          useSuccessToast(i18n.global.t('updateUserSuccess', { user: updatedUser?.name }), sendNotifications)
        }

        return true
      } catch (e) {
        return useApiErrorToast(e as ApiError, { defaultMessage: i18n.global.t('updateUserError'), sendNotifications })
      } finally {
        this.pendingIds.delete(resourceId)
      }
    },

    /*
      Wrapper around this.updateEnabled and this.update to use in lists
    */
    async updateItemInList(
      resourceId: string,
      requestBody: UserUpdate,
      sendNotifications = true,
    ): Promise<true | ErrorMessage> {
      const updateItemResponse = await this.updateItem(resourceId, requestBody, sendNotifications)

      if (updateItemResponse !== true) {
        return updateItemResponse
      }

      const updateResponse = await this.fetch(sendNotifications)

      if (updateResponse !== true) {
        return updateResponse
      }

      return true
    },

    async update(sendNotifications = true) {
      const response = await this.fetch(sendNotifications)
      return response
    },

    async updateSort(orderBy: UsersOrderByFields) {
      this.viewSettings.orderBy = orderBy
      this.viewSettings.page = 1

      return this.fetch()
    },

    async updatePage(page: number) {
      this.viewSettings.page = page

      return this.fetch()
    },

    async updateSize(size: number) {
      this.viewSettings.size = size
      this.viewSettings.page = 1

      return this.fetch()
    },

    clearSearch() {
      this.viewSettings.name = ''
      this.viewSettings.page = 1

      return this.fetch()
    },
  },
})

export default useUsersStore
