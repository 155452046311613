<template>
  <div class="flex h-screen flex-col justify-center bg-secondary-50 dark:bg-secondary-900">
    <div class="flex flex-col items-center">
      <div class="flex flex-col">
        <img class="mb-4 max-h-14 self-start" src="@/assets/images/ghost.svg" alt="Ghost" data-test="gs-logo">
        <template v-if="error.statusCode || error.statusMessage">
          <h1 v-if="error.statusCode" class="mb-2 text-4xl font-bold text-secondary-800 dark:text-secondary-50">
            {{ error.statusCode }}
          </h1>
          <h3 v-if="error.statusMessage" class="text-secondary-600 dark:text-secondary-400">
            {{ error.statusMessage }}
          </h3>
        </template>
        <h3 v-else class="text-secondary-600 dark:text-secondary-400">
          {{ $t('anUnknownErrorOccurredWhileLoading') }}
        </h3>

        <Button v-if="is4XXError" class="mt-4" @click="router.push('/')">
          {{ $t('returnToApps') }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import * as Sentry from '@sentry/vue'
import useInitialTheme from '@/composables/useInitialTheme'

const props = defineProps({
  error: {
    type: Object,
    default: () => ({}),
  },
})

const router = useRouter()

definePageMeta({
  layout: 'empty',
})

const is4XXError = computed(() => {
  const statusCode = props.error?.statusCode || ''
  return statusCode.toString().startsWith('4')
})

onBeforeMount(() => {
  useInitialTheme()
})

onMounted(() => {
  const statusCode = props.error?.statusCode || ''
  if (statusCode.toString().startsWith('5')) {
    Sentry.captureException(props.error)
    Sentry.showReportDialog()
  }
})
</script>
