<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <DynamicDialog />
  <Toast position="bottom-right" />
</template>

<script setup lang="ts">
import DynamicDialog from 'primevue/dynamicdialog'
import Toast from 'primevue/toast'
import { storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import { useAuth0 } from '@auth0/auth0-vue'
import useInitialTheme from '@/composables/useInitialTheme'
import useToastsStore from '@/stores/toasts'

const toastsStore = useToastsStore()
const toast = useToast()
const nuxtApp = useNuxtApp()

const { toast: storeToast } = storeToRefs(toastsStore)

onBeforeMount(() => {
  useInitialTheme()
})

// Provide the access token to the application - mainly used for API requests to /server
if (useAuth0().isAuthenticated.value) {
  const accessToken = await useAuth0().getAccessTokenSilently()
  nuxtApp.provide('accessToken', accessToken)
}

/**
 * This is the main watcher for all app wide notifications.
 */
watch(storeToast, () => {
  toast.add(storeToast.value)
})
</script>
